/* Navigation Arrows */
.swiper-button-next,
.swiper-button-prev {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
  padding: 20px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: rgba(255, 255, 255, 0.8);
  color: #333;
  transform: scale(1.1);
}

/* Pagination Dots */
.swiper-pagination-bullet {
  background: #28666E;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.swiper-pagination-bullet-active {
  background: white;
  transform: scale(1.2);
  box-shadow: 0px 0px 10px white;
}

/* Custom Cube Shadow */
.custom-swiper .swiper-slide {
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.6);
}
